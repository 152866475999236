@media (max-width: 55.68625em) {
    .btn {
        max-width:323px;
        width: 100%;
        min-height: 48px;
        height: 48px
    }
}



@media (max-width: 55.68625em) {
    .btn-outline {
        max-width:163px;
        font-size: 15px;
        height: 48px!important;
        padding: 18px 15px
    }
}


@media (max-width: 55.68625em) {
    .btn-arrow {
        font-size:15px;
        padding: 14px 25px 14px 25px;
        background-size: 15px 18px;
        background-position: 92% 50%
    }
}



.hero {
    /* padding-top: 142px; */
    /* background-image: url('../../public/images/bg-hero.svg'); */
    background-color: #202125;
    /* background-position: top left!important;
    background-repeat: no-repeat!important;
    background-size: contain!important */
}

.hero .d-flex {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px
}


.hero__img-desktop {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 600px
}


.hero__info {
    max-width: 584px;
}

.hero__info h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 22px;
    color: #cacede;
}

.hero__info p {
    color: #cacede;
    max-width: 501px;
    font-style: normal;
    font-weight: 300;
    line-height: 131.523%;
    margin-bottom: 30px
}

.hero__info .d-flex {
    gap: 21px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.hero__info .d-flex a.btn {
    height: 56px
}

@media (max-width: 55.68625em) {
    .hero__info .d-flex a.btn {
        max-width:323px;
        width: 100%;
        min-height: 48px;
        height: 48px
    }
}

.hero .brands {
    padding: 36px 0;
    text-align: center
    
}

.hero .brands p {
    color: #b3b3b3;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px
}


.hero .brands .d-grid {
    display: -ms-grid;
    display: grid;
    margin: 25px 0;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr; /* Define grid columns for IE */
    grid-template-columns: repeat(6, 1fr); /* Define grid columns for modern browsers */
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


.hero .brands .d-grid img {
    max-width: 129px;
    width: 90%;
    margin: 0 auto;
    display: block
}

@media (max-width: 1180px) {
    .hero__info h1 {
        font-size:28px
    }

    .hero .brands .d-grid img {
        max-width: 100px
    }
}

@media (max-width: 1680px){
    .hero__img-desktop img{
        width: 640px;
        height: 70%;
    }
}

@media (max-width: 67.56125em) {
    .hero__info {
        max-width:350px
    }

    .hero .d-flex {
        gap: 15px
    }

    .hero__img-desktop img{
        max-width: 100%;
        height: 70%;
    }
}

@media (max-width: 55.68625em) {
    .hero__info {
        max-width:310px
    }

    .hero__img-desktop {
        padding: 22px 15px 22px
    }

}

@media (max-width: 46.31125em) {
    .hero .d-flex {
        -webkit-box-align:center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .hero__info .d-flex {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 20px
    }

    .hero .brands {
        padding: 30px 0
    }

    .hero .brands p {
        font-size: 10px;
        line-height: normal;
        margin-bottom: 2px
    }



    .hero .brands .d-grid {
        margin: 10px 0;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 2fr 2fr 2fr; /* Define grid columns for IE */
        grid-template-columns: repeat(3, 2fr); /* Define grid columns for modern browsers */
        row-gap: 15px;
        -webkit-column-gap: 5px;
        -moz-column-gap: 5px;
        column-gap: 5px;
    }
    

    .hero .brands .d-grid img {
        max-width: 80px;
        text-align: center;
        height: auto
    }

    .hero__img-desktop {
        display: none
    }

    .hero__info {
        text-align: center;
        margin: 0 auto
    }

    .hero .d-flex {
        gap: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .hero__info h1 {
        margin-bottom: 35px;
        font-size: 25px
    }

    /* .hero {
        padding-top: 100px
    } */

    .hero__info .d-flex a.btn {
        margin-bottom: 14px
    }

    .hero__info .d-flex a.btn:last-child {
        margin-bottom: 0
    }
}





    
   


@-webkit-keyframes lineTop {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: 578px
    }
}

@keyframes lineTop {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: 578px
    }
}

@-webkit-keyframes lineBottom {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: -658px
    }
}

@keyframes lineBottom {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: -658px
    }
}















.header {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    padding: 35px 0;
    z-index: 10;
    -webkit-transition: all .6s ease;
    transition: all .6s ease
}

@media (max-width: 67.56125em) {
    .header {
        padding:17px 0
    }
}

.header .container {
    padding: 0 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 67.56125em) {
    .header .container {
        padding:0 25px
    }
}



@media (max-width: 67.56125em) {
    .menu-open .header._header-scroll {
        padding:17px 0
    }
}


@media (max-width: 67.56125em) {
    .header__logo svg {
        max-width:90px;
        height: auto
    }
}

.header nav a.btn {
    min-height: 37px;
    height: 37px
}

@media (max-width: 46.31125em) {
    .header nav a.btn {
        min-height:48px;
        height: 48px
    }
}









.scroller {
    max-width: 2000px;
  }
  
  .scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .scroller[data-animated="true"] {
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  }
  
  .scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
      var(--_animation-direction, forwards) linear infinite;
  }
  
  
  .scroller[data-direction="left"] {
    --_animation-direction: forwards;
  }
  
  .scroller[data-speed="fast"] {
    --_animation-duration: 20s;
  }
  

  @keyframes scroll {
    to {
      transform: translate(calc(-30% - 0.5rem));
    }
  }
  




