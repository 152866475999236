.features-section{
    background-color: #1a1b1b;
}

.features-section .container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 125px;
    width: 100%;
    
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width: 1300px) {
    .container {
        padding:0 40px
    }
}

@media (max-width: 1180px) {
    .container {
        padding:0 20px
    }
}
.features-section {
    padding: 50px 0 108px;
    position: relative;
    text-align: left
}

.features-section .img-bg {
    position: relative;
    bottom: -114px;
    max-width: 810px;
    width: 100%;
    z-index: 0
}

.features-section .d-flex {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.features-section .features-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 14px;
    text-align: left;
    margin-bottom: 62px;
    max-width: 536px
}

.features-section .features-item:last-child {
    margin-bottom: 0
}

.features-section .features-item h2 {
    color: #edeef1;
    font-weight: 500;
    line-height: 131.523%
} 

@media (max-width: 1440px) {
    .features-section .img-bg {
        max-width:650px
    }
}

@media (max-width: 67.56125em) {
    .features-section .img-bg {
        display:none
    }
}

@media (max-width: 55.68625em) {
    .features-section {
        padding:20px 0
    }

    .features-section .features-item {
        max-width: 100%;
        margin-bottom: 25px
    }
}

@media (max-width: 46.31125em) {
    .features-section .features-item {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.features-section .features-item p {
    color: #d6f0d6;
    
}


