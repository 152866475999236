/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@import url(https://fonts.bunny.net/css2?family=Inter:wght@300;400;500;600;700&display=swap);* {
  padding: 0;
  margin: 0;
  border: 0
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

/* ::after,::before {
  display: inline-block
} */

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%
}

body,html {
  height: 100%
}

body {
  line-height: 1;
  font-family: Inter;
  font-size: .9375rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#202125
} 

a {
  display: inline-block;
  color: inherit;
  text-decoration: none
}

ul li {
  list-style: none
}


img {
  vertical-align: top
}

h1,h2,h3,h4,h5,h6 {
  font-weight: inherit;
  font-size: inherit
}


body,html {
  height: 100%;
  margin: 0;
  padding: 0;
  min-width: 320px;
  width: 100%;
  font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 23.478px
}

@media (max-width: 46.31125em) {
  body,html {
      font-size:13px;
      line-height: 20.348px
  }
}


#root {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background-color: #202125;
} 

@supports (overflow: clip) {
  #root {
      overflow:clip
  }
}

#root>main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

#root>* {
  min-width: 0
}


.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 125px;
  width: 100%
}

@media (max-width: 1300px) {
  .container {
      padding:0 40px
  }
}

@media (max-width: 1180px) {
  .container {
      padding:0 20px
  }
}

.title-section {
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 50px;
  color: #202125
}

@media (max-width: 1180px) {
  .title-section {
      font-size:22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 30px
  }
}

.title-section span.dark-blue {
  color: #22be44;
}

.title-section span.light-blue {
  color: #22be44;
}

/* .title-section span.green {
  color: #339f85
} */