.service-section {
    padding: 40px 0;
    text-align: center;
    background-color: #202125;
}

.service-section h2.title-section {
    margin-bottom: 12px;
    color: white;
}

.service-section .container p {
    color: aliceblue;
    margin-bottom: 30px;
}

.service-section .d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px; /* Adjusts the space between images */
}

.service-section .d-flex-img {
    display: flex;
    flex-direction: row; /* Ensure the images are in a row */
    align-items: center;
    justify-content: center;
    gap: 80px; /* Adjusts the space between images */
}

.service-section .btns-app {
    margin-top: 47px;
    margin-bottom: 30px;
}

.service-section .btns-app a {
    display: inline-block;
    margin: 0 30px;
}

.link-register {
    color: #22be37;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all .6s ease;
}

.link-register:hover {
    color: #45ad5e;
}




@media (max-width: 1080px) {
    .service-section .btns-app img {
        max-width: 111px;
    }

    .service-section .d-flex-img {
        display: flex;
        flex-direction: row; /* Ensure the images are in a row */
        align-items: center;
        justify-content: center;
        gap: 20px; 
        
    }

    .service-section .d-flex-img img {
        width: 45%;
        
    }
}

@media (max-width: 800px) {
    .service-section .d-flex-img img {
        height: 250px;
        
    }
}