.reviews-section {
    padding: 150px 0 250px;
    background: #202125;
    text-align: left;
    position: relative
}

.reviews-p{
    color: #e3e4e7;
}
.reviews-section .d-flex {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.reviews-section .reviews-info {
    max-width: 489px;
    margin-right: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.reviews-section h2.title-section {
    margin-bottom: 0;
    color:#eef0f8
}

.reviews-section .reviews-info a.btn {
    min-width: 170px
} 

 .img-box {
    position: relative
}

.img-box img {
    width: 100%
}

.review-box {
    position: relative
}

.review-box .first-review {
    position: absolute;
    z-index: 1;
    top: 9px;
    left: -62px;
    padding: 12px 14px;
    max-width: 314px;
    width: 100%;
    height: 56px;
    border-radius: 13px;
    background: #161616;;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 12px;
    font-size: 13px;
    line-height: 133.523%;
    -webkit-box-shadow: 0 6px 19px -5px rgba(0,0,0,.15);
    box-shadow: 0 6px 19px -5px rgba(0,0,0,.15)
}

.review-box .first-review p,.review-box .last-review p {
    color: #d4d4db!important
}

.review-box .first-review img {
    max-width: 32px
}

.review-box .second-review {
    position: absolute;
    z-index: 1;
    padding: 9px 15px;
    max-width: 323px;
    width: 100%;
    height: 69px;
    color: #fff;
    border-radius: 13px;
    background: #2abe22;
    font-size: 13px;
    bottom: 65px;
    right: -81px;
    line-height: 133.523%;
    -webkit-box-shadow: 0 6px 19px -5px rgba(0,0,0,.15);
    box-shadow: 0 6px 19px -5px rgba(0,0,0,.15)
}

.review-box .last-review svg,.review-box .second-review svg {
    margin-top: 2px
}

.review-box .last-review {
    position: absolute;
    z-index: 1;
    padding: 12px 19px 10px;
    max-width: 266px;
    width: 100%;
    height: 56px;
    border-radius: 13px;
    background: #161616;
    font-size: 13px;
    bottom: -13px;
    right: -24px;
    line-height: 133.523%;
    -webkit-box-shadow: 0 6px 19px -5px rgba(0,0,0,.15);
    box-shadow: 0 6px 19px -5px rgba(0,0,0,.15)
}

.reviews-section .reviews-grid {
    padding-top: 90px;
    text-align: center
}

.reviews-section .reviews-grid .container {
    padding: 0 66px
}

.reviews-section .reviews-grid h2 {
    margin-bottom: 45px
} 


.reviews-section .reviews-grid .d-grid {
    display: -ms-grid;
    display: grid;
    row-gap: 32px;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr; 
    grid-template-columns: repeat(3, 1fr); 
    -ms-grid-row-align: stretch;
    align-self: stretch;
    margin-bottom: 51px;
}


.reviews-section .reviews-grid button.btn-outline {
    height: 56px;
    min-width: 170px
}

.reviews-section .reviews-grid .review__item {
    max-width: 403px;
    width: 100%;
    min-height: 248px;
    border-radius: 23px;
    background: #161616;;
    text-align: left;
    padding: 26px 22px;
    margin: 0 auto;
    -webkit-box-shadow: 0 31px 61px -16px rgba(0,0,0,.05);
    box-shadow: 0 31px 61px -16px rgba(0,0,0,.05)
}

.reviews-section .reviews-grid .review__item svg {
    display: inline-block;
    margin-bottom: 11px;
    cursor: pointer;
    fill: #ffd90f
}

.reviews-section .reviews-grid .review__item span {
    font-weight: 500;
    color: #eef0f8;
    display: block;
    margin-bottom: 2px;
    line-height: 131.523%
}

.reviews-section .reviews-grid .review__item strong {
    font-weight: 500;
    color: #e3e4e7;
    display: block;
    margin-top: 9px;
    line-height: 131.523%
}

.reviews-section .reviews-grid .review__item p {
    color: #e3e4e7;
    line-height: 23px;
    font-style: normal;
    min-height: 95px;

}

.star-rating-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 5px
}

.star-rating-item svg {
    width: 100%
} 

@media (max-width: 1180px) {
    .reviews-section .reviews-info {
        max-width:380px
    }

    .review-box .second-review {
        right: -27px
    }

    .reviews-section .reviews-grid .container {
        padding: 0 20px
    }
}

@media (max-width: 67.56125em) {
    .img-box img {
        width:90%;
        height: auto
    }

    .review-box .second-review {
        right: 0
    }

    .review-box .last-review {
        right: 0
    }

    .reviews-section .reviews-grid .d-grid {
        row-gap: 20px;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px
    }
}

@media (max-width: 55.68625em) {
    .reviews-section {
        padding:40px 0 200px
    }

    .last-review,.review-box .first-review,.second-review {
        display: none
    }

    .reviews-section .reviews-info {
        margin-right: 15px;
        max-width: 330px
    }

    .reviews-section .reviews-grid .d-grid {
        -ms-grid-columns: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }
    
}

@media (max-width: 46.31125em) {
    .img-box img {
        width:100%
    }

    .reviews-section .d-flex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .reviews-section .reviews-info {
        max-width: 100%;
        margin-right: 0;
        margin-top: 24px
    }


    .reviews-section .reviews-grid {
        padding-top: 60px
    }

    .reviews-section .reviews-info a.btn {
        display: none
    }

    .reviews-section .reviews-grid .d-grid {
        grid-template-columns: 1fr; /* Define one column with equal width */
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    
    .reviews-section .reviews-grid .review__item {
        min-height: 205px;
        padding: 21px 18px
    }
}