.anonymously-section {
    padding: 40px 0 100px;
    text-align: center;
    background-color: #1c1c1c;
}

.anonymously__content {
    padding: 61px 0 60px 77px;
    border-radius: 23px;
    background: #0b3705;
    -webkit-box-shadow: 0 20px 57px -27px rgba(0,0,0,.15);
    box-shadow: 0 20px 57px -27px rgba(108, 134, 102, 0.15);
    max-width: 1178px;
    width: 100%;
    height: 349px;
    position: relative;
    color: #bce3a8;
    text-align: left;
    margin-top: -180px;
    margin-bottom: 93px
}

.anonymously__content .info {
    max-width: 489px
}

.anonymously__content h2.title-section {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    margin-bottom: 9px
}

.anonymously__content .info a.btn {
    margin-top: 20px;
    height: 56px;
    min-width: 170px;
    text-align: center
}

.anonymously__content img.img {
    position: absolute;
    right: 3px;
    top: -61px;
    width: 100%;
    max-width: 550px
}

.anonymously-section h3.title-section {
    color: #f9faf6;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 13px
}

.anonymously-section p.color {
    color: #f9fcf8;
    margin-bottom: 23px
} 

@media (max-width: 1180px) {
    .anonymously-section h3.title-section,.anonymously__content h2.title-section {
        font-size:25px
    }

    .anonymously__content .info {
        max-width: 360px
    }

    .anonymously__content img.img {
        max-width: 550px;
        right: 0;
        height: auto
    }

    .anonymously__content {
        height: auto
    }
}

@media (max-width: 67.56125em) {
    .anonymously__content img.img {
        max-width:490px
    }
}

@media (max-width: 55.68625em) {
    .anonymously__content img.img {
        max-width:360px;
        top: inherit;
        right: 0;
        bottom: 5px
    }

    .anonymously__content .info a.btn {
        max-width: 157px;
        margin-top: 65px
    }

    .anonymously__content .info p {
        display: none
    }

    .anonymously__content {
        min-height: 250px;
        padding: 25px;
        margin-bottom: 60px
    }
}

@media (max-width: 46.31125em) {
    .anonymously__content img.img {
        max-width:120px
    }

    .anonymously__content .info a.btn {
        height: 48px;
        margin-top: 80
    }
}
