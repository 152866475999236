.country-section {
    padding: 30px 0 50px;
    background-color: #202125;
}

.country-section .content {
    max-width: 1178px;
    width: 100%;
    height: 207px;
    border-radius: 23px;
    background-color: #202125;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 20px 57px -27px rgba(59, 65, 60, 0.15);
    box-shadow: 0 20px 57px -27px rgba(121, 145, 126, 0.15)
}

.country-section .content h2,.country-section .content h3 {
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal
}

.country-section .content h2 {
    color: #32be22
}

.country-section .content h3 {
    color: #65ff60
}

.top-line {
    -webkit-animation: 5s lineTop infinite linear;
    animation: 5s lineTop infinite linear;
    max-width: 565px;
    margin: 0 auto
}

.items-service.bottom-line {
    -webkit-animation: 5s lineBottom infinite linear;
    animation: 5s lineBottom infinite linear;
    width: calc(100% - 30px);
    max-width: 658px;
    margin: 0 auto
}

.items-service {
    border-radius: 67px;
    width: 100%;
    height: 69px
}

.data-service {
    max-width: 640px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;
    position: relative
}

.data-service:before {
    content: "";
    position: absolute;
    width: 250px;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background: -webkit-gradient(linear,left top,right top,color-stop(17.04%,#4d6e48),to(rgba(254,254,254,0)));
    background: linear-gradient(90deg,#202129 17.04%,rgba(48, 49, 48, 0) 100%)
}

.data-service:after {
    content: "";
    position: absolute;
    width: 250px;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    background: -webkit-gradient(linear,left top,right top,color-stop(17.04%,#243026),to(rgba(231, 218, 218, 0)));
    background: linear-gradient(90deg,#202129 17.04%,rgba(48, 49, 48, 0) 100%)
}

@-webkit-keyframes lineTop {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: 578px
    }
}

@keyframes lineTop {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: 578px
    }
}

@-webkit-keyframes lineBottom {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: -658px
    }
}

@keyframes lineBottom {
    0% {
        background-position-x: 0
    }

    100% {
        background-position-x: -658px
    }
}

@media (max-width: 1180px) {
    .country-section .content h2,.country-section .content h3 {
        font-size:33px
    }
}

@media (max-width: 67.56125em) {
    .data-service {
        max-width:500px
    }

    .data-service:after,.data-service:before {
        width: 150px
    }
}

@media (max-width: 55.68625em) {
    .country-section {
        padding:20px 0
    }
}

@media (max-width: 46.31125em) {
    .country-section .container {
        max-width:100%;
        padding: 0!important
    }

    .country-section .content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        height: auto;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .data-service {
        max-width: 100%
    }
}