:root{
    --primary: #fff;
}

/* .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  } */
  .btn {
    border-radius: 8px;
    background: #4bcb6f;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: #fafafa;
    padding: 9px 16px;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 56px;
    -webkit-transition: all .6s ease;
    transition: all .6s ease;
    white-space: nowrap
} 

.btn:hover {
    -webkit-box-shadow: 0 5px 5px 1px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px 1px rgba(0,0,0,.1);
    background-color: #fff;
    color: black;
}


.btn-mobile{
    text-decoration: none;
}
  


.btn--primary{
    /* background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary); */
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition:all 0.3s ease-out ;  
}

/* .btn--outline{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition:all 0.3s ease-out ;  
} */

.btn--outline {
    border-radius: 8px;
    border: 1px solid #202125;
    background: #69c582;
    color: #fff;
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 17px;
    -webkit-transition: all .6s ease;
    transition: all .6s ease
} 

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
    background:#fff ;
    color: #242424;
    transition:all 0.3s ease-out ;  
}