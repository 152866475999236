.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap; /* Added to wrap items */
    justify-content: space-between;
    text-align: left;
    margin-bottom: 50px;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Center align items */
    margin: 0px 0px 20px; /* Reduced margin */
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-span {
    color: #fefefe;
    font-size: 20px;
    margin-bottom: 10px; /* Added margin */
  }
  
  .footer-link-items a {
    color: #fefefe;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 15px;
  }
  
  .footer-link-items a:hover {
    color: #d2cbcb;
    transition: 0.3s ease-out;
  }
  
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
    .footer-link-items {
      width: 100%; /* Full width for smaller screens */
      margin-bottom: 30px; /* Increased margin */
      align-items: center;
    }
  }
  