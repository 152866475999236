.order-section {
    padding: 44px 0 40px;
    text-align: center;
    background-color: #151517;
}

.title-section {
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 50px;
    color: #d5d7e1;
}

@media (max-width: 1180px) {
    .title-section {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 30px;
    }
}

.title-section span.dark-blue {
    color: #22be44;
}

.title-section span.light-blue {
    color: #52b768;
}

.title-section span.green {
    color: #339f85;
} 

.order-section .d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.order-section .item {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 577px;
    width: 100%;
    height: 67px;
    border-radius: 14px;
    background: #212121;
    box-shadow: 0 31px 61px -16px rgba(67, 81, 71, 0.527);
    padding: 14px 13px 11px 15px;
    gap: 5px;
    font-weight: 300;
    text-align: left;
    white-space: nowrap;
}

.slide__item-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #d8dae2;
    padding-left: 10px;
    padding-right: 10px;
}

.order-section .swiper {
    width: 95%;
    height: 100%;
    overflow-y: auto;
    max-height: 414px;
    margin: 20px 20px 20px 20px;
}

.order-items {
    position: relative;
    height: 500px;
    box-shadow: 0 15px 61px 15px rgba(82, 99, 87, 0.527);
} 

.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 11px;
    transform: rotate(90deg);
}

.order-section .item .btn {
    min-width: 140px;
    text-align: center;
    min-height: 37px;
    margin-left: 20px;
}

.order-section .item .country {
    min-width: 140px;
    display: flex;
    align-items: center;
}

.order-section .item .app {
    min-width: 120px;
}

.order-section .item strong {
    font-weight: 500;
    display: block;
    margin-left: 5px;
}

.order-section .item img, .order-section .item picture {
    width: 17.487px;
    height: 17.487px;
    border-radius: 100%;
    margin-right: 7px;
}

.order-section .item:last-child {
    margin-bottom: 0;
}

.advantages-item {
    display: flex;
    align-items: flex-start;
    gap: 14px;
    text-align: left;
    margin-bottom: 40px;
    max-width: 492px;
}

.advantages-item:last-child {
    margin-bottom: 0;
}

.advantages-item h2 {
    color: #d8dae2;
    font-style: normal;
    font-weight: 500;
    line-height: 131.523%;
    margin-bottom: 5px;
}

.advantages-item p {
    font-weight: 300;
    color: #d2dbce;
} 

@media (max-width: 67.56125em) {
    .order-section .item .btn {
        min-width: auto;
    }
}

@media (max-width: 55.68625em) {
    .order-section .d-flex {
        flex-direction: column;
    }

    .order-advantages {
        margin-top: 61px;
    }

    .order-section .item .btn {
        min-width: 140px;
        margin-left: 8px;
    }

    .advantages-item {
        margin-bottom: 30px;
    }
}

@media (max-width: 46.31125em) {
    .order-section {
        padding: 20px 0;
    }

    .order-section .item {
        flex-wrap: wrap;
        min-height: 124px;
        font-size: 13px;
        padding: 14px 13px 11px 13px;
    }

    .order-section .item .btn {
        min-width: 100%;
        margin-left: 0;
    }

    .order-section .swiper {
        height: 550px;
    }

    .advantages-item {
        flex-direction: column;
        font-size: 13px;
    }

    .order-section .item .country {
        width: 114px;
    }

    .order-section .item .app {
        min-width: 70px;
    }
}

.swiper {
    overflow: hidden;
}

.swiper-initialized {
    touch-action: pan-y;
}

.swiper-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}

.swiper-vertical .swiper-wrapper {
    flex-direction: column;
}

.swiper-autoheight .swiper-wrapper {
    align-items: flex-start;
}

.swiper-initialized .swiper-slide {
    flex-shrink: 0;
}

.swiper-android .swiper-slide, .swiper-android .swiper-wrapper {
    transform: translate3d(0, 0, 0);
}

.swiper-button-lock {
    display: none !important;
}

.swiper-wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.swiper-slide {
    flex: 1;
    min-height: 137.5px;
}

.swiper-slide + .swiper-slide {
    margin-top: 10px;
}

.order-section .swiper::-webkit-scrollbar {
    width: 13px;
}

.order-section .swiper::-webkit-scrollbar-track {
    background: #242424;
    border-radius: 10px;
}

.order-section .swiper::-webkit-scrollbar-thumb {
    background: #7dc98a;
    border-radius: 10px;
}

.order-section .swiper::-webkit-scrollbar-thumb:hover {
    background: #578d67;
}

.order-section .item:last-child {
    margin-bottom: 0;
}
